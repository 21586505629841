import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ 'selectbox', 'field_number', 'field_row', 'field_options', 'field_required' ]

  select(e) {
    this.setFields()
  }

  connect() {
    console.log('START FORM FIELD')
    this.setFields()    
  }

  setFields() {
    if (this.selectboxTarget.value == 'TextField') {
      $(this.field_rowTarget).show()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'H1Field') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()
      $(this.field_requiredTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })
  
    } else if (this.selectboxTarget.value == 'H2Field') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()
      $(this.field_requiredTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'DateField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'IntegerField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).show()
      })

    } else if (this.selectboxTarget.value == 'EmailField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'CheckBoxField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'SelectField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).show()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'RadioField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).show()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else if (this.selectboxTarget.value == 'FileField') {
      $(this.field_rowTarget).hide()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })

    } else {
      $(this.field_rowTarget).show()
      $(this.field_optionsTarget).hide()

      this.field_numberTargets.forEach(element => {
        $(element).hide()
      })
    }
  }

  filterToNumber(e) {
    const value = e.target.value
    const numbers = value.replace(/[^0-9]/g, '')
    e.target.value = numbers;
  }
}
