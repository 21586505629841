import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container', 'item']

  checkNumberItems(e, item) {
    if (this.itemTargets.length >= 2) {
      e.preventDefault()
    }
  }

  connect() {
    console.log('START SIGNATURE')
    $(this.containerTarget).on('cocoon:before-insert', this.checkNumberItems.bind(this))
  }

}