import { Controller } from 'stimulus'

export default class extends Controller {
  railsUrlRoot = "https://forms.imt.usp.br"
  surveyId = this.data.get('survey-id')

  static targets = ['button']

  print(e) {
    e.preventDefault()

    fetch(`${this.railsUrlRoot}/surveys/${this.surveyId}/survey_registrations/print`, {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(async response => {
      const printWin = window.open('', '', 'left=100, top=100, width=600, height=400, toolbar=0, scrollbars=0, status=0')
      const html = await response.text()
      const container = $(html).filter('.container').html()
      printWin.document.write(container)
      printWin.print()
    })
    .catch(err => {
      console.log('error', err)
    })

  }

  connect() {
    console.log('START SURVEY REGISTRATION')
  }
}