import Sortable from 'sortablejs'
import Rails from '@rails/ujs'
import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = ['button', 'field']

  end(e) {
    const id = e.item.dataset.id
    const data = new FormData()
    data.append('position', e.newIndex + 1)
    
    Rails.ajax({
      url: this.data.get('url').replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }

  connect() {
    console.log('START SORTABLE')
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

}