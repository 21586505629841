import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ 'button', 'field' ]

  verifCheck() {
    if (this.buttonTarget.checked) {
      $(this.fieldTarget).show()
    } else {
      $(this.fieldTarget).hide()
    }
  }

  connect() {
    console.log('START CHECK BUTTON')
    this.verifCheck()
  }

}