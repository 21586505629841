import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['detail']

  connect() {
    console.log('START TABLE DETAILS')
    $('.historic').hide()
  }

  changeColor(e) {
    let $target = $(e.target)
    // salva cor original da célula
    let color = $target.css('background-color')
    // seleciona linha desta célula, e logo a seguir todas células (children) da linha selecionada
    let row = $target.closest('tr').children()
    // troca cor da linha
    row.css('background-color', '#BBFFFF')
    // ao sair recupera cor original
    $target.on('mouseout', function () {
      row.css('background-color', color)
    })
  }

  showHistoric(e) {
    $('.historic').css('background-color', '#BBFFFF')
    //se a linha estiver oculta, mostre-a, ou vice-versa
    $(e.currentTarget).parent().next().toggle()
  }

}
