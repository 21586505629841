import { Controller } from 'stimulus'

export default class extends Controller {
  railsUrlRoot = "https://forms.imt.usp.br"
  eventId = this.data.get('event-id')

  static targets = ['button']

  execute(e) {
    e.preventDefault()
    console.log(this.eventId)
    this.buttonTarget.disabled = true

    const buttonContent = this.buttonTarget.innerHTML
    this.buttonTarget.textContent = 'Aguarde...'
    
    const that = this

    // fetch permite receber o arquivo via ajax tratando como um blob
    fetch(`${this.railsUrlRoot}/events/${this.eventId}/event_registrations/attachments`, {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = window.URL.createObjectURL(blob)
      // download attribute force to download file instead to browsing
      a.download = 'attachs-registrations.zip'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(a.href)

      that.buttonTarget.disabled = false
      that.buttonTarget.innerHTML = buttonContent
    })
    .catch(err => { 
      alert('error', err)
      that.buttonTarget.disabled = false
      that.buttonTarget.innerHTML = buttonContent
    })
  }

  print(e) {
    e.preventDefault()

    fetch(`${this.railsUrlRoot}/events/${this.eventId}/event_registrations/print`, {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(async response => {
      const printWin = window.open('', '', 'left=100, top=100, width=600, height=400, toolbar=0, scrollbars=0, status=0')
      const html = await response.text()
      const container = $(html).filter('.container').html()
      printWin.document.write(container)
      printWin.print()
    })
    .catch(err => {
      console.log('error', err)
    })

  }

  connect() {
    console.log('START REGISTRATION')
  }
}